.App {
  text-align: center;
  display:flex;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

.container{
  max-width:400px;
  display:flex;
  flex-direction: column;
  background-color: #1A1B21;
  align-items: center;
}

img {
  width:100%;
}

body{
  background-color: #23252C;
  font-family: 'Inter', sans-serif;
}

a, a:visited, a:hover, a:active {
  color: inherit;
  text-decoration:none;
}

button{
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  border-radius: 8px;
  border-color: #E0E0E0;
  border-style: solid;
}

.info__email{
  width: 70%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

h1 {
  color: white;
  margin-bottom: 1px;
}

h2{
  font-weight: 700;
  margin-bottom: 1px;
  color: #F5F5F5;
}

.info__title{
  color: #F3BF99;
  margin-top: 0;
}

.info__emailIcon{
  width:18px;
  margin-right:10px;
}

.info{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.about, .interests{
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.about__text, .interests__text{
  margin-top: 0;
  font-weight: 100;
  font-style: normal;
  color: #DCDCDC;
}

.footer{
  background-color: #161619;
  box-sizing: border-box;
  width: 100%;
  display:flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.footer__image{
  padding:5px;
}

.footer__button{
  width:15%;
}

.footer__button-row{
  width:70%;
  justify-content: space-around;
  display: flex;
  flex-direction: row;
}